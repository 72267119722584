
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpUserEditorVue from '@/components/user/BpUserEditor.vue';
import { User, useUserStore } from '@/stores/user';

export default defineComponent({
  name: 'user-editor',
  components: {
    BpUserEditor: BpUserEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useUserStore(true);

    const user = ref<User>();

    function loadUser() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        user.value = doc;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadUser()
    }, { immediate: true });

    return {
      user,
      store,
    }
  }
});
