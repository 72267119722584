
import { $gettext } from 'vue-gettext';
import { User, defaultUser, useUserStore } from '@/stores/user';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { isEqual, OptionalKeys } from '@/utils/object';
import { useCustomerStore } from '@/stores/customer';
import { useFormErrors } from '@/utils/form-errors';
import { useLanguageStore } from '@/stores/language';
import { useRouter } from 'vue-router';
import { useSalutationStore } from '@/stores/salutation';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';
import { Product, useVersionStore } from '@/stores/version';

export default defineComponent({
  name: 'bp-user-editor',
  props: {
    modelValue: Object as PropType<User>,
    loading: Boolean,
    unwrap: Boolean,
    customerId: String,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const initialUser: OptionalKeys<User, '_id' | '_rev'> = {
      ...defaultUser,
      company: router.currentRoute.value.query.customer as string || '',
    };

    const versionStore = useVersionStore();


    const emailNotificationDownloadProducts = ref<string[]>([]);
    const emailNotificationVideoProducts = ref<string[]>([]);


    const user = ref<OptionalKeys<User, '_id' | '_rev'>>({ ...clone(initialUser), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => [props.modelValue, versionStore.loaded], reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------


    function reset() {
      if (props.modelValue && isEqual(user.value, props.modelValue) && !versionStore.loaded) { return }
      user.value = { 
        ...clone(initialUser), 
        ...props.modelValue ? clone(props.modelValue) : {} 
      };
      emailNotificationDownloadProducts.value = props.modelValue?.email_notifications.download.products.map(product => versionStore.getByUUIDPath(product)?._id || '') || [];
      emailNotificationVideoProducts.value = props.modelValue?.email_notifications.video.products.map(product => versionStore.getByUUIDPath(product)?._id || '') || [];
    }

    function emailExists() {
      return store.emailExists(user.value.email || '', isEdit.value ? user.value._id : []);
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<User>();

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore(true);

    ///-------------------------------------------------------------------
    /// PASSWORD
    ///-------------------------------------------------------------------
    
    const newPassword = ref('');

    const changePasswordData = ref<{
      confirmation: { value: string, dirty: boolean, error: string },
      password: { value: string, dirty: boolean, error: string },
    }>({
      confirmation: {
        value: '',
        dirty: false,
        error: '',
      },
      password: {
        value: '',
        dirty: false,
        error: '',
      },
    });

    ///-------------------------------------------------------------------
    /// EMAIL
    ///-------------------------------------------------------------------

    // function emailExists() {
    //   return store.emailExists(user.value.email || '', isEdit.value ? user.value._id : []);
    // }

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const salutationStore = useSalutationStore();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const languageStore = useLanguageStore();

    ///-------------------------------------------------------------------
    /// EMAIL NOTIFICATIONS
    ///-------------------------------------------------------------------



    function updateEmailNotificationProducts(type: 'download' | 'video', products: string[]) {
      if (type === 'download') {
        emailNotificationDownloadProducts.value = products;
      } else {
        emailNotificationVideoProducts.value = products;
      }

      // Sync user settings
      if (!user.value.email_notifications) {
        return;
      }
      const productUUIDs = products.map(product => (versionStore.getById(product) as Product)?.uuid || '');
      const subscriptions = type === 'download' ? user.value.email_notifications.download : user.value.email_notifications.video
      subscriptions.products = productUUIDs;
    }

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useUserStore();

    async function save() {
      let response;
      if(props.customerId) {
        const customerResponse = await customerStore.readById(props.customerId);
        if(customerResponse && customerResponse.success && customerResponse.data) {
          user.value.company = customerResponse.data._id;
        }
      }
      if (user.value._id) {
        response = await store.update(user.value, changePasswordData.value.password.value);
      } else {
        response = await store.create(user.value, changePasswordData.value.password.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: user.value._id
            ? $gettext('Registered user successfully updated')
            : $gettext('Registered user successfully created'),
          content: user.value._id
            ? $gettext('The registered user with the email address <strong>%{email}</strong> was successfully updated.', { email: user.value.email })
            : $gettext('The registered user with the email address <strong>%{email}</strong> was successfully created.', { email: user.value.email }),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.user.overview' });
        return response.data;
      } else {
        BpToast.show({
          color: 'red',
          title: user.value._id
            ? $gettext('Failed to update registered user')
            : $gettext('Failed to create registered user'),
          content: user.value._id
            ? $gettext('The registered user with the email address <strong>%{email}</strong> could not be updated: %{error}', { email: user.value.email, error: response?.error })
            : $gettext('The registered user with the email address <strong>%{email}</strong> could not be created: %{error}', { email: user.value.email, error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    function saveDisabled() {
      return errors.value.size > 0 || (!!changePasswordData.value.password.value && changePasswordData.value.password.value !== changePasswordData.value.confirmation.value);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      user,
      customerStore,
      emailExists,
      errors,
      isEdit,
      languageStore,
      reset,
      salutationStore,
      save,
      saveDisabled,
      updateEmailNotificationProducts,
      emailNotificationDownloadProducts,
      emailNotificationVideoProducts,
      changePasswordData,
      newPassword,
      setError,
      store,
      versionStore,
    }
  }

})


