import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-user-editor__name-wrapper" }
const _hoisted_2 = { class: "flex gap-md" }
const _hoisted_3 = {
  key: 0,
  style: {"opacity":"0.5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_password_validator = _resolveComponent("bp-password-validator")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unwrap ? 'span' : 'bp-card'), {
    class: "bp-user-editor",
    loading: _ctx.loading || _ctx.customerStore.isLoading(),
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createTextVNode("New registered user")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { user: _ctx.user.email }
          }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [
              _createTextVNode("Edit registered user \"%{user}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled(),
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.user.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.user && _ctx.customerStore.loaded)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-user-editor__form",
            onSubmit: _cache[27] || (_cache[27] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_translate, { tag: "h3" }, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode("Account details")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Email address'),
              error: _ctx.emailExists() ? _ctx.$gettext('This email address does already exist.') : undefined,
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('email', _ctx.$gettext('Email address'), $event))),
              required: "",
              type: "email",
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event))
            }, null, 8, ["label", "error", "modelValue"]),
            (!_ctx.customerId)
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 0,
                  data: _ctx.customerStore.customers,
                  label: _ctx.$gettext('Company'),
                  loading: _ctx.customerStore.isLoading(),
                  onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('company', _ctx.$gettext('Company'), $event))),
                  "close-on-click": "",
                  required: "",
                  "search-property": "company",
                  modelValue: _ctx.user.company,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.company) = $event))
                }, null, 8, ["data", "label", "loading", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Salutation') + ' / ' + _ctx.$gettext('Forename') + ' / ' + _ctx.$gettext('Surname')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_bp_select, {
                    data: _ctx.salutationStore.salutations,
                    onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('salutation', _ctx.$gettext('Salutation'), $event))),
                    clearable: "",
                    "close-on-click": "",
                    "search-property": "name",
                    modelValue: _ctx.user.salutation,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.salutation) = $event)),
                    style: {"width":"unset !important"}
                  }, {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("div", _hoisted_2, [
                        (option.icon)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: option.icon
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(option.name) + " ", 1),
                          (!option._disabled)
                            ? (_openBlock(), _createElementBlock("em", _hoisted_3, "(" + _toDisplayString(option.example) + ")", 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["data", "modelValue"]),
                  _createVNode(_component_bp_input, {
                    modelValue: _ctx.user.forename,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.forename) = $event)),
                    onChangeValid: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setError('forename', _ctx.$gettext('Forename'), $event))),
                    maxlength: 100
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_bp_input, {
                    modelValue: _ctx.user.surname,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.surname) = $event)),
                    onChangeValid: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setError('surname', _ctx.$gettext('Surname'), $event))),
                    maxlength: 100
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Phone'),
              onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('phone', _ctx.$gettext('Phone'), $event))),
              type: "tel",
              modelValue: _ctx.user.phone,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.phone) = $event))
            }, null, 8, ["label", "modelValue"]),
            _cache[33] || (_cache[33] = _createElementVNode("hr", null, null, -1)),
            _createVNode(_component_translate, { tag: "h3" }, {
              default: _withCtx(() => _cache[31] || (_cache[31] = [
                _createTextVNode("Security")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_input, {
              error: (_ctx.changePasswordData.password.dirty && _ctx.changePasswordData.password.error && !!_ctx.changePasswordData.password.value) ? _ctx.changePasswordData.password.error : undefined,
              label: _ctx.$gettext('New password'),
              placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
              valid: !_ctx.changePasswordData.password.value ? undefined : !_ctx.changePasswordData.password.error,
              onChangeDirty: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changePasswordData.password.dirty = $event)),
              onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('password', _ctx.$gettext('New password'), $event))),
              required: !_ctx.isEdit,
              autocomplete: "new-password",
              type: "password",
              modelValue: _ctx.changePasswordData.password.value,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.changePasswordData.password.value) = $event))
            }, {
              text: _withCtx(() => [
                _createVNode(_component_bp_password_validator, {
                  password: _ctx.changePasswordData.password.value,
                  onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changePasswordData.password.error = $event))
                }, null, 8, ["password"])
              ]),
              _: 1
            }, 8, ["error", "label", "placeholder", "valid", "required", "modelValue"]),
            _createVNode(_component_bp_input, {
              error: (_ctx.changePasswordData.confirmation.dirty && _ctx.changePasswordData.password.value !== _ctx.changePasswordData.confirmation.value) ? _ctx.$gettext('The passwords do not match') : undefined,
              disabled: !!_ctx.changePasswordData.password.error,
              label: _ctx.$gettext('Confirm new password'),
              placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
              valid: _ctx.changePasswordData.password.value === _ctx.changePasswordData.confirmation.value,
              onChangeDirty: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changePasswordData.confirmation.dirty = $event)),
              onChangeValid: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setError('confirm_password', _ctx.$gettext('Confirm new password'), $event))),
              autocomplete: "new-password",
              required: !_ctx.isEdit || !!_ctx.changePasswordData.password.value,
              type: "password",
              modelValue: _ctx.changePasswordData.confirmation.value,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.changePasswordData.confirmation.value) = $event))
            }, null, 8, ["error", "disabled", "label", "placeholder", "valid", "required", "modelValue"]),
            _cache[34] || (_cache[34] = _createElementVNode("hr", null, null, -1)),
            _createVNode(_component_translate, { tag: "h3" }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode("Email Notifications")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_fieldset, {
              legend: _ctx.$gettext('New software versions'),
              "intense-border": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bp_input, {
                  label: _ctx.$gettext('Active'),
                  text: _ctx.$gettext('Enable email notifications'),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.user.email_notifications.download.active,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.user.email_notifications.download.active) = $event))
                }, null, 8, ["label", "text", "modelValue"]),
                _createVNode(_component_bp_select, {
                  data: _ctx.versionStore.getRootProducts(),
                  disabled: !_ctx.user.email_notifications.download.active,
                  label: _ctx.$gettext('Products'),
                  "model-value": _ctx.emailNotificationDownloadProducts,
                  transform: _ctx.versionStore.getName,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.updateEmailNotificationProducts('download', $event))),
                  clearable: "",
                  onChangeValid: _cache[21] || (_cache[21] = ($event: any) => (_ctx.setError('email_notifications.download.products', `${_ctx.$gettext('New software versions')} > ${_ctx.$gettext('Products')}`, $event))),
                  required: "",
                  multiple: ""
                }, null, 8, ["data", "disabled", "label", "model-value", "transform"]),
                _createVNode(_component_bp_select, {
                  data: _ctx.languageStore.languages,
                  disabled: !_ctx.user.email_notifications.download.active,
                  label: _ctx.$gettext('Email notification language'),
                  transform: (language) => _ctx.languageStore.getNameOf(language._id),
                  "close-on-click": "",
                  modelValue: _ctx.user.email_notifications.download.language,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.user.email_notifications.download.language) = $event))
                }, null, 8, ["data", "disabled", "label", "transform", "modelValue"])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_bp_fieldset, {
              legend: _ctx.$gettext('New video uploads'),
              "intense-border": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bp_input, {
                  label: _ctx.$gettext('Active'),
                  text: _ctx.$gettext('Enable email notifications'),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.user.email_notifications.video.active,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.user.email_notifications.video.active) = $event))
                }, null, 8, ["label", "text", "modelValue"]),
                _createVNode(_component_bp_select, {
                  data: _ctx.versionStore.getRootProducts(),
                  disabled: !_ctx.user.email_notifications.video.active,
                  label: _ctx.$gettext('Products'),
                  "model-value": _ctx.emailNotificationVideoProducts,
                  transform: _ctx.versionStore.getName,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.updateEmailNotificationProducts('video', $event))),
                  clearable: "",
                  onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setError('email_notifications.video.products', `${_ctx.$gettext('New video uploads')} > ${_ctx.$gettext('Products')}`, $event))),
                  required: "",
                  multiple: ""
                }, null, 8, ["data", "disabled", "label", "model-value", "transform"]),
                _createVNode(_component_bp_select, {
                  data: _ctx.languageStore.languages,
                  disabled: !_ctx.user.email_notifications.video.active,
                  label: _ctx.$gettext('Email notification language'),
                  transform: (language) => _ctx.languageStore.getNameOf(language._id),
                  "close-on-click": "",
                  modelValue: _ctx.user.email_notifications.video.language,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.user.email_notifications.video.language) = $event))
                }, null, 8, ["data", "disabled", "label", "transform", "modelValue"])
              ]),
              _: 1
            }, 8, ["legend"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}